import React from 'react';
import { graphql, Script } from 'gatsby';
import { FieldValues } from 'react-hook-form';
import { useLanguage } from '../../../language/language-provider';
import {
    trackFormStep1Completed,
    trackFormStep1UserInput,
    trackFormStep1View, trackFormStep2UserInput,
    trackFormStep2View, trackFormStep2Completed,
    trackPageView, trackFormStep3CtaClicked,
} from '../../../../util/analytics';
import Container from '../../../primitives/grid/container';
import Typography from '../../../primitives/typography';
import Color from '../../../primitives/colors';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import FormProvider from '../../../primitives/form-provider';
import RowSet from '../../../primitives/grid/row-set';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Input from '../../../primitives/input';
import Select, { SelectOption } from '../../../primitives/select';
import StyledButton, { StyledButtonAsLink } from '../../../primitives/styled-button';
import FormAlert from '../../../primitives/form-alert';
import MediaItemWithDecoration from '../../../primitives/media-item-with-decoration';
import FadeTransitionFramer from '../../../animations/transitions/fade-transition-framer';
import { getLink } from '../../../primitives/link';
import Checkmark from './checkmark.svg';
import ScrollEffect from '../../../animations/components/scroll-effect';

export type GetStartedFormProps = Queries.GetStartedFormPropsQuery;

export const GetStartedIndividualForm = ({
    page1Title,
    page1Copy,
    page2Title,
    page2Copy,
    successPageTitle,
    successPageCopy,
    successPageLink,
}) => {
    const { language } = useLanguage();
    const formRef = React.useRef();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [formState, setFormState] = React.useState('form');
    const [errors, setErrors] = React.useState([]);
    const [formHeight, setFormHeight] = React.useState(0);
    const [formData, setFormData] = React.useState({ embed: true });

    const [form1IsDirty, setForm1IsDirty] = React.useState(false);
    const [form2IsDirty, setForm2IsDirty] = React.useState(false);

    React.useEffect(() => {
        if (formState === 'form') {
            trackFormStep1View();
        } else if (formState === 'calendar') {
            trackFormStep2View();
        }
    }, [formState]);

    React.useEffect(() => {
        let interval: NodeJS.Timer;
        if (formState === 'calendar') {
            interval = setInterval(() => {
                if (document?.activeElement?.tagName?.toLowerCase() === 'iframe' && !form2IsDirty) {
                    trackFormStep2UserInput();
                    setForm2IsDirty(true);
                }
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [form2IsDirty, formState]);

    const postMessageListener = (event: any): void => {
        if (event?.data?.meetingBookSucceeded) {
            trackFormStep2Completed();
            setFormState('thanks');
        }
    };

    React.useEffect(() => {
        window.addEventListener('message', postMessageListener);
        return () => {
            window.removeEventListener('message', postMessageListener);
        };
    }, []);

    const userInputOnForm1 = (): void => {
        if (!form1IsDirty) {
            setForm1IsDirty(true);
            trackFormStep1UserInput();
        }
    };

    const onSubmit = (e: FieldValues): void => {
        if (formRef.current) {
            const { height } = formRef.current.getBoundingClientRect();
            setFormHeight(height);
        }
        setErrors([]);
        setIsSubmitting(true);
        const url = 'https://api.hsforms.com/submissions/v3/integration/submit/4454113/5d520f37-bccc-4b68-a66c-8b132bb27617';

        const data = {
            fields: [
                {
                    name: 'firstname',
                    value: e.firstname,
                },
                {
                    name: 'lastname',
                    value: e.lastname,
                },
                {
                    name: 'email',
                    value: e.email,
                },
                {
                    name: 'phone_number__web_',
                    // eslint-disable-next-line no-underscore-dangle
                    value: e.phone_number__web_,
                },
                {
                    name: 'mobilephone',
                    // eslint-disable-next-line no-underscore-dangle
                    value: e.phone_number__web_,
                },
                {
                    name: 'phone',
                    // eslint-disable-next-line no-underscore-dangle
                    value: e.phone_number__web_,
                },
                {
                    name: 'company',
                    value: e.company,
                },
                {
                    name: 'country_freetext',
                    value: e.countrynew,
                },
                {
                    name: 'countrynew',
                    value: e.countrynew,
                },
            ],
            context: {
                pageUri: window.location.href,
                pageName: 'Uptick Book A Demo Page',
            },
            legalConsentOptions: {
                // Include this object when GDPR options are enabled
                consent: {
                    consentToProcess: true,
                    text: 'I agree to allow Uptick to store and process my personal data.',
                    communications: [
                        {
                            value: true,
                            subscriptionTypeId: 999,
                            text: 'I agree to receive marketing communications from Uptick.',
                        },
                    ],
                },
            },
        };

        // eslint-disable-next-line no-underscore-dangle
        setFormData(fd => ({ ...fd, ...e, phone: e.phone_number__web_, mobile: e.phone_number__web_, countrynew: e.countrynew }));

        fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then((res) => {
                if (res?.errors) {
                    const errs = res?.errors || [];
                    const mappedErrors = errs.map(x => x.message);
                    setErrors(mappedErrors);
                    setIsSubmitting(false);
                    return;
                }
                trackFormStep1Completed();
                setIsSubmitting(false);
                setFormState('calendar');
                trackPageView(`${window.location.pathname}#booking-form-completed`);
            })
            .catch(() => {
                setErrors(['An unknown error occured.']);
            });
    };

    const languageToCountry = {
        au: 'Australia',
        uk: 'UK',
        us: 'US',
    }[language];

    const countryOptions = {
        Australia: 'Australia',
        'New Zealand': 'New Zealand',
        UK: 'United Kingdom',
        Ireland: 'Ireland',
        US: 'United States',
        Canada: 'Canada',
        Other: 'Other',
    };

    const frontline = {
        Australia: 'https://meetings.hubspot.com/anthony-del-mastro/anz-discovery',
        'New Zealand': 'https://meetings.hubspot.com/anthony-del-mastro/anz-discovery',
        UK: 'https://meetings.hubspot.com/kyle-jones1/uptick-discovery-session-uk',
        Ireland: 'https://meetings.hubspot.com/kyle-jones1/uptick-discovery-session-uk',
        US: 'https://meetings.hubspot.com/scott-macritchie/us-discovery',
        Canada: 'https://meetings.hubspot.com/scott-macritchie/us-discovery',
        Other: 'https://meetings.hubspot.com/kyle-jones1/uptick-discovery-session-uk',
    }[formData?.countrynew as keyof typeof countryOptions];

    return (
        <FadeTransitionFramer css={{ height: '100%' }} shouldChange={(formState === 'thanks').toString()}>
            {(formState !== 'thanks') && (
                <div>
                    {page1Title && (
                        <div>
                            <FadeTransitionFramer shouldChange={formState}>
                                <div>
                                    <Typography
                                        fontSize={{
                                            dt: 3234,
                                        }}
                                        css={{
                                            [breakpoints.mb]: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        fontWeight="light"
                                    >
                                        {formState === 'form' && page1Title}
                                        {formState === 'calendar' && page2Title}
                                    </Typography>
                                    <Typography
                                        fontSize={{
                                            dt: 1822,
                                            mb: 1322,
                                        }}
                                        css={{
                                            marginTop: '16px',
                                            opacity: '0.7',
                                            [breakpoints.mb]: {
                                                textAlign: 'center',
                                            },
                                        }}
                                    >
                                        {formState === 'form' && page1Copy}
                                        {formState === 'calendar' && page2Copy}
                                    </Typography>
                                </div>
                            </FadeTransitionFramer>
                        </div>
                    )}
                    <Color
                        backgroundColor="grey50"
                        css={{
                            borderRadius: borderRadius.medium,
                            marginTop: '28px',
                            width: '100%',
                            [breakpoints.mb]: {
                                width: 'fit-content',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            },
                        }}
                    >
                        <FadeTransitionFramer shouldChange={formState}>
                            {formState === 'form' && (
                                <div ref={formRef} css={{ padding: '16px' }}>
                                    <FormProvider
                                        onSubmit={onSubmit}
                                        disabled={isSubmitting}
                                        values={{
                                            countrynew: languageToCountry,
                                        }}
                                    >
                                        <RowSet
                                            css={{
                                                [breakpoints.mb]: {
                                                    width: '80vw',
                                                },
                                            }}
                                            breakpoints={{ dt: { between: 10 }, mb: { between: 4 } }}
                                        >
                                            <Input onChange={userInputOnForm1} name="email" type="email" placeholder="Email" required />
                                            <Input onChange={userInputOnForm1} name="company" placeholder="Company name" required />
                                            <Input onChange={userInputOnForm1} name="firstname" placeholder="First name" required />
                                            <Input onChange={userInputOnForm1} name="lastname" placeholder="Last name" required />
                                            <Input onChange={userInputOnForm1} name="phone_number__web_" placeholder="Mobile phone" required />
                                            <Select
                                                css={{
                                                    marginTop: '4px',

                                                }}
                                                name="countrynew"
                                                placeholder="Country"
                                                required
                                                onChange={userInputOnForm1}
                                            >
                                                {Object.entries(countryOptions).map(([k, v]) => (
                                                    <SelectOption value={k}>{v}</SelectOption>
                                                ))}
                                            </Select>
                                            <div>
                                                <StyledButton
                                                    css={{
                                                        marginTop: '20px',
                                                    }}
                                                    type="submit"
                                                    loading={isSubmitting}
                                                    className="mx-auto d-block"
                                                >
                                                    Submit
                                                </StyledButton>
                                            </div>
                                        </RowSet>
                                        {errors.map(error => (
                                            <div key={error}>
                                                <FormAlert>{error}</FormAlert>
                                            </div>
                                        ))}
                                    </FormProvider>
                                </div>
                            )}
                            {formState === 'calendar' && (
                                <div
                                    css={{
                                        margin: 'auto',
                                        width: '100%',
                                        maxHeight: '644px',
                                        '@media (max-width: 1466px)': {
                                            maxHeight: '610px',
                                        },
                                    }}
                                    style={{ minHeight: `${formHeight}px` }}
                                >
                                    <Script src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
                                    <div
                                        className="meetings-iframe-container"
                                        style={{ height: '760px' }}
                                        data-src={`${frontline}?${new URLSearchParams(formData).toString().replace(/\+/g, '%20')}`}
                                    />
                                </div>
                            )}
                        </FadeTransitionFramer>
                    </Color>
                </div>
            )}
            {formState === 'thanks' && (
                <div css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
                >
                    <RowSet breakpoints={{
                        dt: { between: 32 },
                    }}
                    >
                        <ScrollEffect
                            inViewProperties={{
                                opacity: [0, 1],
                                y: [5, 0],
                            }}
                            inViewDelay={0.3}
                        >
                            <div css={{
                                [breakpoints.mb]: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                },
                            }}
                            >
                                <Checkmark />
                            </div>
                        </ScrollEffect>
                        <ScrollEffect
                            inViewProperties={{
                                opacity: [0, 1],
                                y: [5, 0],
                            }}
                            inViewDelay={0.45}
                        >
                            <Typography
                                fontSize={{
                                    dt: 3234,
                                }}
                                css={{
                                    [breakpoints.mb]: {
                                        textAlign: 'center',
                                    },
                                }}
                                fontWeight="light"
                            >
                                {successPageTitle}
                            </Typography>
                            <Typography
                                fontSize={{
                                    dt: 1822,
                                    mb: 1322,
                                }}
                                css={{
                                    marginTop: '16px',
                                    opacity: '0.7',
                                    [breakpoints.mb]: {
                                        textAlign: 'center',
                                    },
                                }}
                            >
                                {successPageCopy}
                            </Typography>
                        </ScrollEffect>
                        <ScrollEffect
                            inViewProperties={{
                                opacity: [0, 1],
                                y: [5, 0],
                            }}
                            inViewDelay={0.6}
                        >
                            <StyledButtonAsLink
                                variant="darkPrimary"
                                onClick={() => {
                                    trackFormStep3CtaClicked();
                                }}
                                to={getLink(successPageLink?.[0]?.link)}
                            >
                                {successPageLink?.[0]?.title}
                            </StyledButtonAsLink>
                        </ScrollEffect>
                    </RowSet>
                </div>
            )}
        </FadeTransitionFramer>
    );
};

const GetStartedForm = ({
    page1Title,
    page1Copy,
    page2Title,
    page2Copy,
    successPageTitle,
    successPageCopy,
    successPageLink,
    mediaItem,
}: GetStartedFormProps): JSX.Element => (
    <div css={{
        position: 'relative',
        overflow: 'hidden',
    }}
    >
        <Container css={{
            marginTop: '100px',
            marginBottom: '100px',
            minHeight: '460px',
            [breakpoints.mb]: {
                minHeight: 'unset',
                marginTop: '80px',
                marginBottom: '80px',
            },
        }}
        >
            <Row>
                <Col breakpoints={{ dt: { span: 6 } }}>
                    <GetStartedIndividualForm
                        page1Title={page1Title}
                        page1Copy={page1Copy}
                        page2Title={page2Title}
                        page2Copy={page2Copy}
                        successPageTitle={successPageTitle}
                        successPageCopy={successPageCopy}
                        successPageLink={successPageLink}
                    />
                </Col>
                <Col breakpoints={{ dt: { span: 6 }, mb: { span: 4, hidden: true } }}>
                    <div css={{
                        margin: 'auto 0',
                        paddingLeft: '12%',
                        [breakpoints.tb]: {
                            width: '118%',
                        },
                        [breakpoints.mb]: {
                            marginLeft: '0',
                            width: '100%',
                            paddingLeft: '12%',
                        },
                    }}
                    >
                        <MediaItemWithDecoration
                            css={{
                                height: '100%',
                            }}
                            objectFit="cover"
                            autoPlay
                            muted
                            objectPosition="center center"
                            orientation="decoration-on-left"
                            item={mediaItem}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);

export default GetStartedForm;

export const query = graphql`
    fragment GetStartedFormProps on DatoCmsGetStartedForm {
        page1Title
        page1Copy
        page2Title
        page2Copy
        successPageTitle
        successPageCopy
        successPageLink {
            ...LinkWithTitleProps
        }
        mediaItem {
            ...MediaItemWithDecorationProps
        }
    }
`;
